import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WarrantyRegistrationSuccessComponent = () => {
  const { t } = useTranslation();
  const [pdfUrl, setPdfUrl] = useState<string>("");
  const [statusMsg, setStatusMsg] = useState<string>("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedMsg = urlParams.get("statusMsg");
    const statusMsg =
      encodedMsg && atob(encodedMsg) !== "null" ? atob(encodedMsg) : null;
    const pdfUrl = urlParams.get("pdfUrl");
    if (statusMsg) {
      setStatusMsg(statusMsg);
    }
    if (pdfUrl) {
      setPdfUrl(pdfUrl);
    }
  }, []);
  return pdfUrl === "" && statusMsg !== "" ? (
    <div className="registration-complete" style={{backgroundColor:"#ffddd0"}} >
      <h2>{t("warranty_registration_success.page_content.title_unsuccess")}</h2>
      <p>
        {statusMsg}
      </p>
    </div>
  ) : pdfUrl != "" ? (
    <div className="registration-complete">
      <h2>{t("warranty_registration_success.page_content.title")}</h2>
      <p>
        <a href={pdfUrl} target="_blank">
          {t("warranty_registration_success.page_content.download_link_text")}
        </a>
      </p>
      <p>
        <a href="#">
          {t("warranty_registration_success.page_content.email_send_link_text")}
        </a>
      </p>
      <h6 style={{ color: "gray" }}>
        {t("warranty_registration_success.page_content.note_text")}{" "}
        <a href="https://get.adobe.com/reader/">
          {t("warranty_registration_success.page_content.note_link_text")}
        </a>
        .
      </h6>
      {
        statusMsg!=="" && (
          <p style={{color:"Red"}}>{statusMsg}</p>
        )
      }
    </div>
  ) : null;
};

export default WarrantyRegistrationSuccessComponent;
