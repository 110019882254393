import { HttpResponse, HttpService } from "../../core";

export class WarrantyRegistartionService {
  private readonly baseUrl: string = process.env.REACT_APP_BASE_URL!;
  private httpService: HttpService;
  constructor() {
    this.httpService = new HttpService(this.baseUrl);
  }
  async GetVehicleData<T>(
    vehicleNumber: string,
    countryId: number,
    issearchedbyvin: boolean
  ): Promise<T> {
    return await this.httpService.get<T>(
      `Biluppgifter/GetDetailsForVehicle?number=${vehicleNumber}&countryId=${countryId}&issearchedbyvin=${issearchedbyvin}`
    );
  }

  async GetProductData<T>(
    vehicleRegNo: string,
    langCode: string,
    milage: string,
    modelYear: number,
    brandGroupId: number,
    brandId: number,
    coPartnerId: number,
    isConsiderCoPartner: boolean
  ): Promise<T> {
    return await this.httpService.get<T>(
      `DpWarranty/GetDealerFilteredProducts?vehicleRegNo=${vehicleRegNo}&langCode=${langCode}&milage=${milage}&modelYear=${modelYear}-01-01&brandGroupId=${brandGroupId}&brandId=${brandId}&coPartnerId=${coPartnerId}&isConsiderCoPartner=${isConsiderCoPartner}`
    );
  }

  async GetProductDataById<T>(pId: number): Promise<T> {
    return await this.httpService.get<T>(
      `DpWarranty/ProductDetails?pId=${pId}`
    );
  }

  async GetAddonProductData<R, T>(data: any): Promise<T> {
    return await this.httpService.post<R, T>(
      `DpWarranty/SearchAddOnProductsCustomertoPayPrice`,
      data
    );
  }

  async GetDealerSelectedProductPrice<T>(
    vehicleRegNo: string,
    mileage: string,
    capacity: string,
    productId: number,
    period: number,
    brandId: number
  ): Promise<T> {
    return await this.httpService.get<T>(
      `DpWarranty/GetDealerProductPrice?vehicleRegNo=${vehicleRegNo}&mileage=${mileage}&capacity=${capacity}&productId=${productId}&period=${period}&brandId=${brandId}`
    );
  }

  async GetPersonalData<T>(personalNo: string): Promise<T> {
    return await this.httpService.get<T>(
      `Bilvision/GetBilvisionPersonData?personalNo=${personalNo}`
    );
  }

  async GeDealerData<T>(): Promise<T> {
    return await this.httpService.get<T>(`DpWarranty/GetDealerName`);
  }

  async GeBrandGroupData<T>(lCode: string): Promise<T> {
    return await this.httpService.get<T>(
      `DpWarranty/GetWebsiteBrandGroups?langCode=${lCode}`
    );
  }

  async GeBrandsData<T>(brandGroupId: string): Promise<T> {
    return await this.httpService.get<T>(
      `DpWarranty/GetWebsiteBrands?brandGroupId=${brandGroupId}`
    );
  }

  async GetWarrantyLetter<T>(insuranceId: number): Promise<T> {
    return await this.httpService.get<T>(
      `DpWarranty/GetWarrantyAndTermsCertificatesPDF?insuranceId=${insuranceId}`
    );
  }

  async GetProductTermsPDF<T>(productId: number, lCode: string): Promise<T> {
    return await this.httpService.get<T>(
      `DpWarranty/GetProductTermsPDF?productId=${productId}&langCode=${lCode}`
    );
  }

  async CreateInsurance<R, T>(data: any): Promise<T> {
    return await this.httpService.post<R, T>(`DpWarranty/Add`, data);
  }
}
