import { IState } from "./index";

export const initialState: IState = {
  apiCallInProgress: 0,
  vehicleData: {},
  productData: {
    data: [
      {
        pId: 0,
        productName: "",
        excessAmount: "",
        certificateDescription: "",
        maxKm: 0,
        maxLength: 0,
        periods: [],
        startDate: new Date(),
        period: 12,
      },
    ],
  },
  periodData: {
    data: {
      isVehicleCatagoriesAvail: true,
      pId: 0,
      dealerName: "",
      dealerId: 0,
      coverDate: "",
      brandGroupId: 0,
      period: [],
      coOperatePartner: {
        companyName: "",
        companyId: "",
        address: "",
        zipCode: "",
        city: "",
        eMail: "",
        teleNos: [],
      },
      vehicleType: "",
      vehicleTypeId: "",
      brands: [],
      maxKm: 0,
    },
  },
  addonProductData: {
    data: [
      {
        Id: 0,
        Name: "0",
        Description: "0",
        PeriodList: null,
        MileageCap: null,
        YearCap: null,
        InsuranceType: null,
        ProductPriceId: null,
        Price: 0.0,
        PeriodCount: null,
        Period: 0,
        PricePeriodList: [
          {
            Period: "",
            Price: "",
          },
        ],
      },
    ],
  },
  personalData: {
    socialSecurityNumber: "",
    id: 0,
    lastName: "",
    firstName: "",
    dob: "",
    address: "",
    zipCode: "",
    city: "",
    email: "",
    countryId: 0,
    countryName: "",
    teleMobile: "",
    cellPhone: "",
  },
  dealerData: {
    data: {
      DealerName: "",
      DealerId: 0,
      CusRepName: "",
    },
    status: true,
  },
  brandGroupData: {
    data: [
      {
        Id: 0,
        Name: "",
      },
    ],
  },
  brandsData: {
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  mbiProductPrice: { price: 0 },
  selectedProducts: [
    {
      id: 0,
      name: "",
      startDate: "",
      endDate: "",
      period: 0,
      award: 0,
      type: "",
    },
  ],
  insuranceData: {
    success: true,
    warrantyId: 0,
    welcomeLetter: true,
    isGenerateWelcomeLetter: true,
    isFirstWarrantyCreatedEmailSend: false,
    insuranceId: 0,
    isMainIns: false,
    status:"",
    statusCode:"",
    addOnInsuranceStates:[]
  },
  warrantyOverviewData: {
    data: [
      {
        id: 0,
        regNo: "",
        vinNo: "",
        productName: "",
        validFrom: "",
        validTo: "",
        brandName: "",
        modelName: "",
        dealerName: "",
        vehicleOwnerName: "",
      },
    ],
  },
  priceListData: {
    data: {
      Url: "",
    },
  },
  productNameListData: {
    data: [
      {
        pId: 0,
        productName: "",
      },
    ],
  },
  dealerProductData: {
    data: {
      pId: 0,
      productName: "",
      subHeading: "",
      description: "",
      termUrl: "",
      compUrl: "",
      imgUrl: null,
      componentIdWithCover: "",
      components: [
        {
          name: "",
          description: "",
        },
      ],
    },
  },
  offerLetterLoginDetails: {
    access_token: "",
    countryID: "",
    cultureCode: "",
    expires_in: 0,
    isCompany: false,
    isFirstTimePasswordChanged: true,
    isPolicyAccepted: true,
    isValidCustomerInfo: true,
    loginflow: "",
    refreshToken_timeout: "",
    refresh_token: "",
    token_type: "",
    userName: "",
    userTypeId: 0,
    webUserId: 0,
    responsecode: "000",
    body: {},
  },
  pdfDocUrl: { data: { Url: "" } },
  termDocUrl: { data: { Url: "" } },
  isSuccess: false,
  baseResponse: undefined,
};
